
import { Options, Prop, Vue } from "vue-property-decorator";

@Options({
  components: {},
})
export default class Education extends Vue {
  public onClickEducationCard(): void {
    if (this.url != null && this.url != "") {
      window.open(this.url, "_blank");
    } else {
      this.$toasted.error(this.$t("my_driving_school.invalid_url"));
    }
  }

  @Prop({ type: String })
  public url!: string;

  @Prop({ type: String })
  public bgColor!: string;

  @Prop({ type: String })
  public textColor!: string;
}
